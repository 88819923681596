import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Button, Typography, Box } from '@mui/material';
// import { useHistory } from 'react-router-dom';
import { useNavigate, Link } from '@remix-run/react';

import { Container } from '~/content/components/Container';

import pic from '~/content/images/not-found-cover/coffee_redux.jpg';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useRouteLoaderData } from '@remix-run/react';

const NotFound = (): JSX.Element => {
	let { t } = useTranslation(['notFound']);
	// const { userLanguage } = useLoaderData();
	// const { userLanguage }: any = useRouteLoaderData('routes/($lang)._dashboard');
	const { userLanguage }: any = useRouteLoaderData('root');
	const link_prefix = userLanguage === 'en' ? '/en' : '';

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	//* click go back
	// const history = useHistory();
	// const goBack = () => {
	// 	history.goBack();
	// };
	// const navigate = useNavigate();
	// const goBack = () => {
	// 	// navigate(-1);
	// 	navigate('.', {
	// 		replace: true,
	// 		relative: 'path',
	// 	});
	// };

	return (
		<Box
			sx={{
				width: 1,
				height: 1,
				overflow: 'hidden',
			}}
		>
			<Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
				<Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} position={'relative'}>
					<Box width={1} order={{ xs: 2, md: 1 }} display={'flex'} alignItems={'center'}>
						<Container>
							<Box>
								{/* <Typography
										variant="h1"
										component={'h1'}
										align={isMd ? 'left' : 'center'}
										sx={{ fontWeight: 700 }}
									>
										404
									</Typography> */}
								<Typography variant='h6' color='text.secondary' align={isMd ? 'left' : 'center'}>
									{t('headline')}
									<br></br>
									{/* Oops! Looks like you followed a bad link. */}
									<br />
									{t('info_1')} <br />
									<Link
										// to={'/contact?scrollTo=contact-form'}
										to={link_prefix + '/contact/?scrollTo=contact-form'}
										prefetch='intent'
									>
										<Typography variant='h6' color='text.primary'>
											{t('info_2')}
										</Typography>
									</Link>
								</Typography>
								<Box
									marginTop={4}
									display={'flex'}
									justifyContent={{ xs: 'center', md: 'flex-start' }}
								>
									<Link to={'..'} replace>
										<Button variant='contained' color='primary' size='large'>
											{/* Back home */}
											{t('back')}
										</Button>
									</Link>
								</Box>
							</Box>
						</Container>
					</Box>
					<Box
						sx={{
							flex: { xs: '0 0 100%', md: '0 0 50%' },
							position: 'relative',
							maxWidth: { xs: '100%', md: '50%' },
							order: { xs: 1, md: 2 },
							minHeight: { xs: 'auto', md: 'calc(100vh - 58px)' },
						}}
					>
						<Box
							sx={{
								width: { xs: 1, md: '50vw' },
								height: '100%',
								position: 'relative',
							}}
						>
							<Box
								sx={{
									width: '100%',
									height: '100%',
									overflow: 'hidden',
								}}
							>
								<Box
									sx={{
										overflow: 'hidden',
										left: '0%',
										width: 1,
										height: 1,
										position: { xs: 'relative', md: 'absolute' },
										clipPath: {
											xs: 'none',
											md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
										},
										shapeOutside: {
											xs: 'none',
											md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
										},
									}}
								>
									<Box
										sx={{
											height: { xs: 'auto', md: 1 },
											'& img': {
												objectFit: 'cover',
											},
											// '& .lazy-load-image-loaded': {
											//   height: 1,
											//   width: 1,
											// },
										}}
									>
										<Box
											component={'img'}
											src={pic}
											height={{ xs: 'auto', md: 1 }}
											maxHeight={{ xs: 300, md: 1 }}
											width={1}
											maxWidth={1}
											sx={{
												filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
											}}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default NotFound;
