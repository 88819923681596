export { default } from './page/_index.tsx';
import { externalAction, externalLoader } from '~/utils/externalLoader.server';
export const action = externalAction;
// export const loader = externalLoader;
import type { MetaFunction } from '@remix-run/node';

import { json } from '@remix-run/node'; // or cloudflare/deno
export const loader = async () => {
	return json(
		{
			message: 'Not Found',
		},
		{
			status: 404,
		},
	);
};

export const meta: MetaFunction = () => {
	return [
		{
			title: '404 - Not Found',
			description: 'The page you are looking for does not exist.',
		},
		{
			name: 'robots',
			content: 'noindex, nofollow',
		},
	];
};
